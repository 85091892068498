import request from '@/utils/request'

// 登录
export const Login = (tel) => request.post('/api/auth/login', { tel })

// 主分类
export const MainTag = () => request.get('/api/travel_manage/get_tag_main_list')

// 区块图
export const getPhoto = (id) => request.get('/api/album/get_photo_list', { params: { photo_class_id: id } })
