<template>
  <div class="login">
    <div class="header">
      <div class="wrapper top">
        <div class="logo"></div>
      </div>
    </div>

    <!-- 登录背景 -->
    <div class="loginInp">
      <div class="loginBox">
        <div class="formBox">
          <el-form ref="loginRef" :model="loginForm" :rules="loginRules">
            <el-form-item prop="tel">
              <el-input
                v-model="loginForm.tel"
                placeholder="手机号码/邮箱"
              ></el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                v-model="loginForm.password"
                placeholder="密码"
                type="password"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="loginFn" class="login-btn"
                >登录</el-button
              >
              <el-link type="info">去注册</el-link>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '@/utils/request'
export default {
  name: 'LoginIndex',
  created () {},
  data () {
    return {
      loginForm: {},
      loginRules: {
        tel: [
          { required: true, message: '请输入手机号或者邮箱', trigger: 'blur' },
          {
            pattern:
              /^1[3-9]\d{9}$/ ||
              /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(.[a-zA-Z0-9_-]+)+$/,
            message: '请输入正确的手机号或邮箱',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^\S{6,15}$/,
            message: '密码必须是6-15的非空字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    loginFn () {
      this.$refs.loginRef.validate((valid) => {
        if (valid) {
          request.post('/api/auth/login', { ...this.loginForm })
            .then((res) => {
              this.$store.commit('token/setUserInfo', res.data)
              this.$message({
                message: '登录成功,正在跳转回登录前页面',
                type: 'success'
              })
              setTimeout(() => {
                this.$router.push('/')
              }, 500)
            })
            .catch(() => {
              this.$message.error('手机号或密码错误!')
            })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.login {
  .header {
    background-color: #303030;
    .top {
      height: 70px;
      .logo {
        width: 285px;
        height: 70px;
        background: url(@/assets/logotrp.png) no-repeat center;
      }
    }
  }

  .loginInp {
    height: 1080px;
    height: 548px;
    background: #95a5b4 url(@/assets/登录背景.jpg) no-repeat center;
    position: relative;
    .loginBox {
      position: absolute;
      top: 110px;
      right: 400px;
      width: 355px;
      height: 260px;
      background-color: #fff;
      .formBox {
        width: 300px;
        height: 100%;
        margin: 40px auto;
        .login-btn {
          width: 100%;
        }
      }
    }
  }
}
</style>
