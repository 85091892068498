<template>
  <div id="home">
    <div class="header">
      <div class="header-top">
        <div class="wrapper top-menu">
          <div class="left-menu">
            <div @click="goLogin">登录</div>
            <div>注册</div>
          </div>
          <div class="right-menu">
            <div>我的订单</div>
            <div>帮助中心</div>
            <div>我的社区</div>
            <div>手机APP</div>
            <div>社群</div>
            <i class="el-icon-edit" style="font-size: 15px"></i>
            <i class="el-icon-share" style="font-size: 15px"></i>
          </div>
        </div>
      </div>
      <div class="header-middle">
        <div class="wrapper web-info">
          <h1 id="logo"></h1>
          <div class="search">
            <el-dropdown>
              <span
                class="el-dropdown-link"
                style="font-size: 18px; color: #000"
              >
                杭州站<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="index in 10" :key="index"
                  >杭州站</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <div class="search-box">
              <input type="text" class="search-inp" />
              <div class="search-btn">搜索</div>
            </div>
            <div class="free-phone" style="font-weight: 700; font-size: 18px">
              400 000 0000
            </div>
          </div>
        </div>
      </div>
      <div class="header-nav">
        <ul class="wrapper nav">
          <li v-for="item in main_category_list" :key="item">{{ item }}</li>
        </ul>
      </div>
    </div>

    <div class="swiper">
      <el-carousel :interval="5000" arrow="always" height="500px">
        <el-carousel-item>
          <img src="@/assets/banner.jpg" alt="" />
        </el-carousel-item>

        <el-carousel-item>
          <img src="@/assets/banner2.jpg" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 优秀博主 -->
    <div class="wrapper blogger">
      <div class="blogger-left">
        <div class="li-title">游侠攻略</div>
        <el-carousel
          :interval="5000"
          arrow="hover"
          height="370px"
          direction="vertical"
        >
          <el-carousel-item v-for="item in 4" :key="item">
            <img src="@/assets/blogger.jpg" class="sw-img" />
          </el-carousel-item>
        </el-carousel>
      </div>

      <div class="blogger-right">
        <div class="li-title">
          <div class="left">游侠风采</div>
          <ul class="right">
            <li>户外高手</li>
            <li>摄影高手</li>
            <li>亲子导师</li>
            <li>更多</li>
          </ul>
        </div>
        <el-row :gutter="9">
          <el-col :span="4" v-for="item in 6" :key="item">
            <div class="grid-content bg-purple-dark">
              <img
                src="@/assets/游侠女.jpg"
                alt=""
                height="100%"
                width="100%"
              />
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="9">
          <el-col :span="4" v-for="item in 6" :key="item">
            <div class="grid-content bg-purple">
              <img src="@/assets/游侠2.jpg" alt="" height="100%" width="100%" />
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 广告 -->
    <div class="wrapper">
      <el-carousel
        trigger="click"
        height="304px"
        arrow="never"
        class="middle-adv"
      >
        <el-carousel-item v-for="item in 4" :key="item">
          <div style="background-color: #ffec00; height: 100%">
            <img src="@/assets/大广告.jpg" alt="" width="100%" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 图片内容 -->
    <div class="wrapper">
      <div class="theme-title">
        <div class="li-title">
          <div>周边短途游</div>
          <ul class="right">
            <li>特惠线路</li>
            <li>大型活动</li>
            <li>新品推荐</li>
            <li>更多</li>
          </ul>
        </div>
      </div>
      <div class="theme">
        <div class="category">
          <div class="one_cate" style="font-size: 15px;margin:15px;">
            热门目的地
            <ul class="destination">
              <li>多彩贵州</li>
              <li>西北传奇</li>
              <li>坝上草原</li>
              <li>呼伦贝尔</li>
              <li>多彩贵州</li>
              <li>西北传奇</li>
              <li>坝上草原</li>
              <li>呼伦贝尔</li>
            </ul>
          </div>
          <div class="one_cate" style="font-size: 15px;margin:15px;">
            热门主题
            <ul class="destination">
              <li>越野派</li>
              <li>沙漠旅行</li>
              <li>登山徒步</li>
              <li>小车自驾</li>
              <li>尊享团</li>
            </ul>
          </div>
          <div class="one_cate" style="font-size: 15px;margin:15px;">
            游玩天数
            <ul class="destination">
              <li>4-7天</li>
              <li>8-9天</li>
              <li>9天以上</li>
            </ul>
          </div>
        </div>
        <div class="view-img">
          <el-row :gutter="8">
            <el-col :span="16">
              <!-- <div class="grid-content bg-purple">1</div> -->
              <img :src="category_img_list[1].photo_file_url" alt="">
            </el-col>
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple">2</div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
          </el-row>

          <el-row :gutter="8">
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple"></div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple"></div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple"></div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
          </el-row>
        </div>
        <div class="right-swiper">
          <el-carousel :interval="5000" arrow="always" height="460px">
            <el-carousel-item v-for="item in 4" :key="item">
              <!-- <h3>{{ item }}</h3> -->
              <img :src="category_img_list[0].photo_file_url" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <!-- 图片内容 -->
    <div class="wrapper">
      <div class="theme-title">
        <div class="li-title">
          <div>国内长线游</div>
          <ul class="right">
            <li>特惠线路</li>
            <li>大型活动</li>
            <li>新品推荐</li>
            <li>更多</li>
          </ul>
        </div>
      </div>
      <!-- <div class="theme">
        <div class="category"></div>
        <div class="view-img">
          <el-row :gutter="8">
            <el-col :span="16">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>

          <el-row :gutter="8">
            <el-col :span="8">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
        </div>
        <div class="right-swiper">
          <el-carousel :interval="5000" arrow="always" height="460px">
            <el-carousel-item v-for="item in 4" :key="item">
              <h3>{{ item }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div> -->
      <div class="theme">
        <div class="category">
          <div class="one_cate" style="font-size: 15px;margin:15px;">
            热门目的地
            <ul class="destination">
              <li>多彩贵州</li>
              <li>西北传奇</li>
              <li>坝上草原</li>
              <li>呼伦贝尔</li>
              <li>多彩贵州</li>
              <li>西北传奇</li>
              <li>坝上草原</li>
              <li>呼伦贝尔</li>
            </ul>
          </div>
          <div class="one_cate" style="font-size: 15px;margin:15px;">
            热门主题
            <ul class="destination">
              <li>越野派</li>
              <li>沙漠旅行</li>
              <li>登山徒步</li>
              <li>小车自驾</li>
              <li>尊享团</li>
            </ul>
          </div>
          <div class="one_cate" style="font-size: 15px;margin:15px;">
            游玩天数
            <ul class="destination">
              <li>4-7天</li>
              <li>8-9天</li>
              <li>9天以上</li>
            </ul>
          </div>
        </div>
        <div class="view-img">
          <el-row :gutter="8">
            <el-col :span="16">
              <!-- <div class="grid-content bg-purple">1</div> -->
              <img :src="category_img_list[1].photo_file_url" alt="">
            </el-col>
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple">2</div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
          </el-row>

          <el-row :gutter="8">
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple"></div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple"></div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
            <el-col :span="8">
              <!-- <div class="grid-content bg-purple"></div> -->
              <img :src="category_img_list[2].photo_file_url" alt="">
            </el-col>
          </el-row>
        </div>
        <div class="right-swiper">
          <el-carousel :interval="5000" arrow="always" height="460px">
            <el-carousel-item v-for="item in 4" :key="item">
              <!-- <h3>{{ item }}</h3> -->
              <img :src="category_img_list[0].photo_file_url" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <!-- 小广告 -->
    <div class="wrapper" style="margin-top: 40px">
      <img src="@/assets/广告.jpg" alt="" />
      <img src="@/assets/广告.jpg" alt="" style="float: right" />
    </div>

    <!-- 精选游记 -->
    <div class="wrapper" style="margin-top: 40px">
      <div class="theme-title">
        <div class="li-title">
          <div>活动游记</div>
          <ul class="right">
            <li>新疆</li>
            <li>上海</li>
            <li>四川</li>
            <li>云南</li>
            <li>贵州</li>
            <li>香港</li>
            <li>内蒙</li>
            <li>更多</li>
          </ul>
        </div>
      </div>
      <div class="youji">
        <div class="youji-item" v-for="item in 8" :key="item">
          <img src="@/assets/配图.jpg" alt="" />
          <div class="youji-info">
            <span>意大利——永恒与光辉交织的浪漫国度</span>
            <span>2024-09-06 by PictureZhou</span>
          </div>
        </div>
        <div class="youji-item last">发表游记</div>
      </div>
    </div>

    <!-- 精选照片 -->
    <div class="wrapper" style="margin-top: 40px">
      <div class="theme-title">
        <div class="li-title">
          <div>精彩摄影作品</div>
          <ul class="right">
            <li>旅行</li>
            <li>风光</li>
            <li>人像</li>
            <li>航拍</li>
            <li>纪实</li>
            <li>生态</li>
            <li>其它</li>
            <li>更多</li>
          </ul>
        </div>
      </div>
      <div class="photo">
        <el-row :gutter="7">
          <el-col :span="6">
            <div class="grid-content l-one"></div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content"></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"></div>
          </el-col>
        </el-row>
        <el-row :gutter="7">
          <el-col :span="6" :offset="6">
            <div class="grid-content"></div>
          </el-col>
          <el-col :span="12">
            <div class="grid-content"></div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- 视频 -->
    <div class="wrapper" style="margin-top: 40px">
      <div class="theme-title">
        <div class="li-title">
          <div>精选视频</div>
          <ul class="right">
            <li>延时</li>
            <li>色彩</li>
            <li>访谈</li>
            <li>活动</li>
            <li>更多</li>
          </ul>
        </div>
      </div>
      <div class="video">
        <div v-for="item in 4" :key="item" class="video-item"></div>
      </div>
    </div>

    <!-- 底部 -->
    <div class="bottom">
      <a href="https://beian.miit.gov.cn" target="_blank"
        >粤ICP备2024259748号-1</a
      >
    </div>
  </div>
</template>

<script>
import { getPhoto } from '@/api'

export default {
  name: 'HomePage',
  async created () {
    // MainTag().then(res => {
    //   console.log(res)
    // })
    const res = await getPhoto(5)
    this.category_img_list = res.data
  },
  data () {
    return {
      main_category_list: ['周边游', '国内游', '出境游'],
      category_img_list: []
    }
  },

  methods: {
    goLogin () {
      this.$router.push({
        path: '/login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
#home {
  background-color: #f9f9f9;
}
.li-title {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  margin: 12px 0;
  .right {
    margin: 0;
    display: flex;
    li {
      margin-left: 22px;
    }
  }
}
.header {
  .header-top {
    background-color: #2b2b2b;

    .top-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      color: #ccc;
      font-size: 12px;

      .left-menu {
        display: flex;
        justify-content: space-around;
        width: 90px;
      }

      .right-menu {
        display: flex;
        justify-content: space-around;
        width: 519px;
      }
    }
  }

  .header-middle {
    .web-info {
      display: flex;
      height: 110px;
      justify-content: space-between;
      align-items: center;

      #logo {
        height: 55px;
        width: 200px;
        background-color: red;
      }

      .search {
        display: flex;
        align-items: center;

        .search-box {
          display: flex;
          margin: 0 25px;

          .search-inp {
            width: 420px;
            border: 2px solid #ffec00;
            height: 36px;
            border-radius: 36px 0 0 36px;
          }

          .search-btn {
            width: 132px;
            height: 100%;
            line-height: 42px;
            font-size: 18px;
            font-weight: 700;
            background-color: #ffec00;
            text-align: center;
            border-radius: 0 42px 42px 0;
          }
        }
      }
    }
  }

  .header-nav {
    height: 50px;
    position: relative;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
    z-index: 9999;

    .nav {
      list-style: none;
      display: flex;
      padding: 0;

      li {
        font-size: 14px;
        margin: 0 30px;
        line-height: 50px;
      }
    }
  }
}

.el-carousel {
  text-align: center;
}

.blogger {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  .blogger-left {
    width: 286px;
    margin-right: 9px;
  }

  .blogger-right {
    .el-row {
      margin-bottom: 8px;
    }

    .grid-content {
      width: 143px;
      height: 181px;
      background-color: #ffec00;
    }
  }
}

.middle-adv {
  margin: 30px 0 40px 0;
}

.theme-title {
  margin: 12px 0;
}

.theme {
  display: flex;
  justify-content: space-between;
  height: 460px;
  // margin-top: 30px;
  margin-bottom: 40px;
}

.category {
  width: 222px;
  height: 100%;
  background-color: #fff;
  .destination {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    li {
      font-size: 13px;
      margin: 5px 20px;
    }
  }
}

.view-img {
  width: 728px;
  height: 100%;

  .el-row {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .bg-purple {
    height: 226px;
    background: #d3dce6;
  }
}

.right-swiper {
  width: 236px;
}

.youji {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // margin-top: 30px;

  .youji-item {
    width: 395px;
    height: 124px;
    // background-color: #ffec00;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .youji-info {
      margin-left: 16px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-size: 15px;
    }
  }
  .last {
    background-color: #ffec00;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
}

.photo {
  height: 605px;
  background-color: #2b2b2b;
  .grid-content {
    margin-bottom: 7px;
    height: 197px;
    background-color: skyblue;
  }
  .l-one {
    height: 401px;
  }
}

.video {
  display: flex;
  justify-content: space-between;
  .video-item {
    width: 294px;
    height: 170px;
    background-color: skyblue;
  }
}

.bottom {
  background-color: #333333;
  height: 50px;
  margin-top: 40px;
  color: #fff;
  text-align: center;
  line-height: 50px;
}
</style>
