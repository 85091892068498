<template>
  <div id="art">
    <div class="top">
      <el-carousel height="635px" class="centered-carousel">
        <el-carousel-item v-for="item in 4" :key="item">
          <img src="@/assets/游记大图.jpeg" alt="" width="100%" />
        </el-carousel-item>
      </el-carousel>
      <div class="search">
        <el-input v-model="input" placeholder="游记/旅行攻略/目的地"></el-input>
      </div>
    </div>
    <div class="wrapper">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="热门游记" name="first"></el-tab-pane>
        <el-tab-pane label="推荐游记" name="second"></el-tab-pane>
        <el-tab-pane label="最新游记" name="third"></el-tab-pane>
      </el-tabs>
      <div class="article_item">
        <div class="img_left">
          <img src="@/assets/NjYyODA3.jpg" alt="">
        </div>
        <div class="des_right">
          <h3 class="title">夜探古猗</h3>
          <p>古猗园位于上海市西北郊嘉定区南翔镇。园林最早建于明代嘉靖年间，离市中心21公里，占地10公顷。它以绿竹猗猗、静曲水幽、建筑典雅、楹联诗词以及花石小路等五大特色闻名。园林包括猗园、花香仙苑、曲溪鹤影、幽篁烟月四大景区，内有</p>
          <div class="user_info">
            <span class="loction">地点：杭州西湖</span>
            <div class="avatar">
              <img src="@/assets/aca.jpg" alt="">
            </div>
            <span class="hot">4500阅读</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ArticleIndex',
  data () {
    return {
      activeName: 'second',
      input: ''
    }
  },
  methods: {
    handleClick (tab, event) {
      console.log(tab, event)
    }
  }
}
</script>
<style lang="less" scoped>
.top {
  position: relative;
  .centered-carousel .el-carousel__container {
    justify-content: center;
  }

  .centered-carousel .el-carousel__item {
    text-align: center;
    overflow: hidden;
  }
  .search {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99999;
  }
}

.article_item {
  width: 70%;
  display: flex;
  .img_left {
    margin-right: 20px;
  }
  .des_right {

    height: 145px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h3,p {
      margin: 0;
    }
  }
}
</style>
